<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      persistent
      max-width="500"
      v-if="resolutionScreen >= 500"
    >
      <v-overlay :value="loaddataprogress" z-index="3" color="black" dark>
        <v-progress-circular indeterminate size="64" color="white">
          <span :style="colorProgress"></span>
        </v-progress-circular>
      </v-overlay>
      <v-card>
        <!-- <v-card-title
          ><v-icon large :color="filedata.file_icon[1]" class="pb-2 pr-2">{{ filedata.file_icon[0] }}</v-icon
          >{{ filedata.file_name }}
          <v-spacer></v-spacer><v-icon :color="color.theme" dense dark size="35">delete_outline</v-icon>
          </v-card-title
        > -->
        <v-card-text class="pa-0">
          <v-list class="pa-0">
            <v-list-item>
              <v-list-item-action>
                <v-icon large :color="filedata.file_icon[1]">
                  {{ filedata.file_icon[0] }}</v-icon
                >
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size:large;">{{
                  filedata.file_name
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon dense dark size="35" :color="color.theme"
                  >delete_outline</v-icon
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout row wrap align-center justify-center>
            <v-flex xs11 lg11 ma-5>
              <v-card class="elevation-0">
                <v-layout row wrap justify-start class="pb-3">
                  <v-flex lg1>
                    <v-icon color="red">delete</v-icon>
                  </v-flex>
                  <v-flex lg10 xs10>
                    {{ $t("checkpassword.removepassword") }}
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-center>
                  <v-flex lg10 xs10 class="text-center">
                    <v-text-field
                      persistent-hint
                      multiple
                      outlined
                      dense
                      v-model="password"
                      :type="showpassword ? 'text' : 'password'"
                      :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showpassword = !showpassword"
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            outlined
            dark
            @click="$emit('close'), (password = '')"
            >{{ $t("checkpassword.cancel") }}</v-btn
          >
          <v-btn color="primary" @click="deletepassword(), (password = '')">{{
            $t("checkpassword.ok")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="show"
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      v-else
    >
      <v-overlay :value="loaddataprogress" z-index="3" color="black" dark>
        <v-progress-circular indeterminate size="64" color="white">
          <span :style="colorProgress"></span>
        </v-progress-circular>
      </v-overlay>
      <v-card>
        <v-card-text class="pa-5">
          <v-layout class="pt-2 pb-4">
            <v-flex d-flex justify-center class="ml-8">
              <span :style="headerPage">{{
                $t("checkpassword.deletepassword")
              }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon
                :color="color.theme"
                @click="$emit('close'), (password = '')"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
          <span :style="titleText">{{ $t("checkpassword.removepassword") }}</span>
          <v-text-field
            class="mt-1 textFieldClass"
            :color="color.theme"
            outlined
            dense
            v-model="password"
            :type="showpassword ? 'text' : 'password'"
            :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
            height="35px"
            @click:append="showpassword = !showpassword"
            autocomplete="off"
            persistent-hint
          ></v-text-field>
          <div class="text-center">
            <v-btn
              :style="btnAction"
              class="mr-6"
              height="40px"
              width="45%"
              color="red"
              outlined
              @click="$emit('close'), (password = '')"
              >{{ $t("checkpassword.cancel") }}</v-btn
            >
            <v-btn
              class="elevation-0"
              :style="btnAction"
              height="40px"
              width="45%"
              :color="color.theme"
              :dark="color.darkTheme"
              @click="deletepassword(), (password = '')"
              >{{ $t("checkpassword.ok") }}</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  data() {
    return {
      password: "",
      showpassword: false,
      loaddataprogress: false,
    };
  },
  props: ["show", "filedata"],
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    colorProgress() {
      //return "color:" + this.color.theme + ";";
      return "color:" + "white" + ";";
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษร header ของ mobile
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "color:black; font-size: 14px; line-height: 28px;";
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
  },
  methods: {
    async deletepassword() {
      if (this.password !== "") {
        if (this.filedata.file_type === "folder") {
          let payload = {
            folder_id: this.filedata.file_id,
            account_id: this.dataAccountId,
            status: "N",
            password: this.password,
          };
          this.loaddataprogress = true;
          let auth = await gbfGenerate.generateToken();
          console.log(payload);
          await this.axios
            .post(
              process.env.VUE_APP_SERVICE_UPDATE_FOLDER +
                "/api/remove/lock_folder",
              payload,
              { headers: { Authorization: auth.code } }
            )
            .then((response) => {
              if (response.data.status === "OK") {
                this.$emit("closedialog");
                this.loaddataprogress = false;
                Toast.fire({
                  icon: "success",
                  title: this.$t("checkpassword.deletepasswordsuccess"),
                });
              } else {
                this.loaddataprogress = false;
                Toast.fire({
                  icon: "error",
                  title: this.$t("checkpassword.passwordincorrect"),
                });
              }
            });
        } else {
          let payload = {
            file_id: this.filedata.file_id,
            account_id: this.dataAccountId,
            status: "N",
            password: this.password,
          };
          this.loaddataprogress = true;
          let auth = await gbfGenerate.generateToken();
          console.log(payload);
          await this.axios
            .post(
              process.env.VUE_APP_SERVICE_UPDATE_FILE + "/api/remove/lock_file",
              payload,
              { headers: { Authorization: auth.code } }
            )
            .then((response) => {
              if (response.data.status === "OK") {
                this.loaddataprogress = false;
                Toast.fire({
                  icon: "success",
                  title: this.$t("checkpassword.deletepasswordsuccess"),
                });
                this.$emit("closedialog");
              } else {
                this.loaddataprogress = false;
                Toast.fire({
                  icon: "error",
                  title: this.$t("checkpassword.passwordincorrect"),
                });
              }
            });
        }
      } else {
        Toast.fire({
          icon: "error",
          title: this.$t("checkpassword.enterpassword"),
        });
      }
    },
  },
};
</script>
<style>
.v-text-field.textFieldClass .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-input.textFieldClass input {
  font-size: 14px;
}
</style>
